import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue';
import Index from '@/views/Index.vue'; // 假设你有一个 Index.vue 组件
Vue.use(VueRouter)

const routes = [

  // 登录都可以访问
  {
    path: '/',
    component: Login,

  },


  // 登录后的主页 需要判断是否登录成功

  {
    path: '/index',
    component: Index,

    children: [

      { path: '/views', component: () => import('@/views/Welcome.vue') },

      // { path: '/views', component: () => import('@/views/vipEcord/List.vue') },

      // { path: '/views/category', component: () => import('@/views/category/List.vue') },
      // { path: '/views/edit', component: () => import('@/views/category/Edit.vue') },
      // { path: '/views/appedit', component: () => import('@/views/category/AppEdit.vue') },


      // { path: '/views', component: () => import('@/views/views/List.vue') },//老用户
      // { path: '/views/AD', component: () => import('@/views/views/AD/AD.vue')},//新用户
      // { path: '/views/insert', component: () => import('@/views/views/insert.vue') },
      // { path: '/views/category', component: () => import('@/views/views/category/List.vue') },
      // { path: '/views/category/withdrawal', component: () => import('@/views/views/category/Withdrawal.vue') },//提现申请
      // { path: '/views/video', component: () => import('@/views/views/video/List.vue')}, 
      // { path: '/views/update', component: () => import('@/views/views/video/update.vue')},       

      // { path: '/views/insert', component: () => import('@/views/views/video/Insert.vue')},    

      // { path: '/views/vip', component: () => import('@/views/views/vip/List.vue')},
      // { path: '/views/update', component: () => import('@/views/views/vip/update.vue')},    
      // { path: '/views/insert', component: () => import('@/views/views/vip/Insert.vue')},  

      // { path: '/views/gold', component: () => import('@/views/views/gold/gold.vue')},  //金币表
      // { path: '/views/hitvideo', component: () => import('@/views/views/hitVideo/List.vue')},  
      // { path: '/views/insert', component: () => import('@/views/views/hitVideo/Insert.vue')},
      // { path: '/views/update', component: () => import('@/views/views/hitVideo/Update.vue')},  

      // { path: '/user', component: () => import('@/views/user/List.vue') },

      //老用户版本控制
      { path: '/views/appVersion/AppVersionVue', component: () => import('@/views/appVersion/AppVersion.vue') },
      // 反馈
      { path: '/views/feedback/FeedbackVue', component: () => import('@/views/feedback/Feedback.vue') },
      // 金币
      { path: '/views/gold/GoleVue', component: () => import('@/views/gold/Gold.vue') },
      // 热搜推荐
      { path: '/views/hitVideo/HitVideoVue', component: () => import('@/views/hitVideo/HitVideo.vue') },
      { path: '/views/hitVideo/InsertHitVideoVue', component: () => import('@/views/hitVideo/InsertHitVideo.vue') },
      { path: '/views/hitVideo/UpdateHitVideoVue', component: () => import('@/views/hitVideo/UpdateHitVideo.vue') },
      // 新用户版本控制
      { path: '/views/newAppVersion/NewAppVersionVue', component: () => import('@/views/newAppVersion/newAppVersion.vue') },
      { path: '/views/newAppVersion/InsertNewAppVersionVue', component: () => import('@/views/newAppVersion/InsertNewAppVersion.vue') },
      { path: '/views/newAppVersion/UpdateNewAppVersionVue', component: () => import('@/views/newAppVersion/UpdateNewAppVersion.vue') },
      // 提现
      { path: '/views/payouts/PayoutsVue', component: () => import('@/views/payouts/Payouts.vue') },
      // 提现审核
      { path: '/views/payouts/WithdrawalVue', component: () => import('@/views/payouts/Withdrawal.vue') },
      // 用户
      { path: '/views/user/UserVue', component: () => import('@/views/user/User.vue') },
      { path: '/views/user/UpdateUserVue', component: () => import('@/views/user/UpdateUser.vue') },
      // 短剧
      { path: '/views/video/VideoVue', component: () => import('@/views/video/Video.vue') },
      { path: '/views/video/InsertVideoVue', component: () => import('@/views/video/InsertVideo.vue') },
      { path: '/views/video/UpdateVideoVue', component: () => import('@/views/video/UpdateVideo.vue') },
      // vip
      { path: '/views/vip/VipVue', component: () => import('@/views/vip/Vip.vue') },
      { path: '/views/vip/InsertVipVue', component: () => import('@/views/vip/InsertVip.vue') },
      { path: '/views/vip/UpdateVipVue', component: () => import('@/views/vip/UpdateVip.vue') },
      // vip记录
      { path: '/views/vipEcord/vipEcordVue', component: () => import('@/views/vipEcord/VipEcord.vue') },

    ]
  },
  { path: '/', component: Index },
]


// 假设的isAuthenticated函数，用于检查用户是否已登录  
function isAuthenticated() {
  // 这里只是一个示例，实际项目中你可能需要检查localStorage、Vuex或调用API  

}


//原始代码
const router = new VueRouter({
  routes
})


// router.beforeEach((to, from, next) => {
//   // 状态库持久化
//   const sessionState = sessionStorage.getItem("state")
//   if (sessionState) {
//     store.replaceState(JSON.parse(sessionState))
//   }

//   if (!store.getters.isLogin && to.path != '/login') {
//     return next('/login')
//   }

//   next(true);
// })

export default router
