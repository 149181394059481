import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'echarts';
import ECharts from 'vue-echarts';


// import echarts from 'echarts';
// Vue.prototype.$echarts = echarts;    //该项目内的组件页面使用都要用this.$echarts

import Vuex from 'vuex' 
Vue.use(Vuex);


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAxios, axios)

Vue.component('ECharts',ECharts)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
