import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    activeMenu: '/'
  },
  getters: {
    isLogin: state => !!state.user,
    nickname: state => state.user?.nickname
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
      sessionStorage.setItem("state", JSON.stringify(state))
    },
    setActiveMenu(state, payload) {
      state.activeMenu = payload;
      sessionStorage.setItem("state", JSON.stringify(state))
    }
  },
  actions: {
  },
  modules: {
  }
})
